<template>
  <Pending v-if="pending" />
  <PageLayout padding v-else>
    <template #header>
      <h2 class="display-3 text-center">
        Bonjour <strong class="ml-1">{{ user?.name }}</strong>
      </h2>
      <hr />
      <BaseLegend>
        Choisissez l'établissement sur lequel vous souhaitez travailler.
      </BaseLegend>
    </template>
    <template #default>
      <div class="p-field p-fluid">
        <label>Etablissement :</label>
        <Dropdown
          class="mt-2"
          v-model="selectedCenter"
          :options="centers"
          optionLabel="name"
          optionValue="id"
          placeholder="Selectionnez"
        ></Dropdown>
      </div>
      <div
        class="center-details"
        v-for="center in centers.filter((c) => c.id === selectedCenter)"
        :key="center.id"
      >
        <Divider><span class="text-sm text-muted">Détails</span></Divider>
        <div
          class="flex align-items-center"
          :class="
            !center.details
              ? 'justify-content-center'
              : 'justify-content-between'
          "
        >
          <div class="flex flex-column ml-3" v-if="center.details">
            <span>{{ center.details?.address }}</span>
            <span
              >{{ center.details?.zipcode }} {{ center.details?.city }}</span
            >
            <strong class="mt-2"
              ><i class="fa fa-phone" />
              {{ center.details?.phonenumber }}</strong
            >
          </div>
          <EditCenter :data="center" v-can:editCenters="user.role" />
        </div>
      </div>
    </template>
    <template #footer>
      <ActionsNav class="justify-content-end">
        <BaseButtonSet
          label="Suivant"
          icon="fa fa-arrow-right"
          :color="!selectedCenter ? 'secondary' : 'success'"
          :disabled="!selectedCenter"
          :text="!selectedCenter"
          @click="onNext"
        />
      </ActionsNav>
    </template>
  </PageLayout>
</template>

<script>
import { ref, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { getUser } from "../composables/getUser";
import useLogout from "../composables/useLogout";
import { formatFromString } from "../utils/date-format";
import useDatabase from "../composables/useDatabase";
import useRules from "../security/rules";

import EditCenter from "./_EditCenter";
import { projectAuth } from "../firebase/config";

export default {
  components: { EditCenter },
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const { can } = useRules();
    const { logout } = useLogout();

    const { docs: centers, pending } = useDatabase("centers").getAll();

    watch([pending], async () => {
      if (!pending.value) {
        const idTokenResult = await projectAuth.currentUser.getIdTokenResult();
        const accessAllCenters = await can("accessAllCenters");
        if (accessAllCenters) {
          centers.value = centers.value.filter((c) => !c.disabled);
        } else {
          centers.value = centers.value.filter(
            (c) => idTokenResult.claims.centers[c.id] && !c.disabled
          );
          selectedCenter.value = centers.value[0]?.id;
        }
      }
    });

    const selectedCenter = ref(null);

    const onNext = () => {
      if (selectedCenter.value) {
        router.push({
          name: "Boards",
          params: { centerId: selectedCenter.value },
        });
      }
    };

    const cloud = reactive({
      activeBoards: null,
      error: null,
      pending: null,
    });

    return {
      selectedCenter,
      onNext,
      centers,
      logout,
      formatFromString,
      ...toRefs(cloud),
      pending,
      user,
    };
  },
};
</script>

<style lang="scss" scoped>
.center-details {
  //padding: 1rem;
}
</style>
