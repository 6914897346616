<template>
  <div v-if="!pending">
    <Button
      icon="pi pi-pencil"
      class="p-button-secondary p-button-text"
      :label="!center.details ? 'Mettre à jour les informations' : ''"
      @click="visible = true"
    ></Button>
    <Dialog modal v-model:visible="visible" header="Modifier les informations">
      <template #default>
        <form id="form" @submit.prevent="onSubmit" class="p-field">
          <div class="p-fluid">
            <label for="address" class="mb-1">Adresse</label>
            <InputText id="address" v-model="details.address" />
          </div>
          <div class="p-fluid">
            <label for="zipcode" class="mb-1">Code postal</label>
            <InputMask id="zipcode" v-model="details.zipcode" mask="99999" />
          </div>
          <div class="p-fluid">
            <label for="city" class="mb-1">Ville</label>
            <InputText id="city" v-model="details.city" />
          </div>
          <div class="p-fluid">
            <label for="phonenumber" class="mb-1">Téléphone</label>
            <InputMask
              id="phonenumber"
              v-model="details.phonenumber"
              mask="99 99 99 99 99"
            />
          </div>
        </form>
      </template>
      <template #footer>
        <Button
          label="Retour"
          class="p-button-text p-button-secondary"
          @click="visible = false"
        />
        <Button
          label="Enregistrer"
          icon="pi pi-save"
          class="p-button-success"
          type="submit"
          form="form"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { reactive, toRefs, ref, watch } from "vue";
import useDatabase from "../composables/useDatabase";
import { useToast } from "primevue/usetoast";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const visible = ref(false);

    const { getOne, updateOne } = useDatabase(`centers`);
    const { doc: center, error, pending } = getOne(props.data.id);

    const input = reactive({
      name: center.value.name,
      details: {
        address: center.value.details?.address || null,
        zipcode: center.value.details?.zipcode || null,
        city: center.value.details?.city || null,
        phonenumber: center.value.details?.phonenumber || null,
      },
    });

    watch([visible, pending], () => {
      if (!pending.value) {
        input.name = center.value.name;
        input.details = {
          address: center.value.details?.address || null,
          zipcode: center.value.details?.zipcode || null,
          city: center.value.details?.city || null,
          phonenumber: center.value.details?.phonenumber || null,
        };
      }
    });

    const onSubmit = async () => {
      const { error } = await updateOne(props.data.id, input);
      if (!error.value) {
        toast.add({
          severity: "success",
          detail: "Informations de l'établissement mises à jours",
          life: 1500,
        });
      }
      visible.value = false;
    };

    return { visible, onSubmit, center, error, pending, ...toRefs(input) };
  },
};
</script>

<style lang="scss" scoped></style>
