import { fromUnixTime, format } from "date-fns";

const DAYS = {
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mer",
  Thu: "Jeu",
  Fri: "Ven",
  Sat: "Sam",
  Sun: "Dim",
};

const MONTHS = {
  Jan: "Janvier",
  Feb: "Février",
  Mar: "Mars",
  Apr: "Avril",
  May: "Mai",
  Jun: "Juin",
  Jul: "Juillet",
  Aug: "Août",
  Sep: "Septembre",
  Oct: "Octobre",
  Nov: "Novembre",
  Dec: "Décembre",
};

const formatFromTimestamp = (timestamp, pattern = "dd/MM/Y") => {
  return format(fromUnixTime(timestamp), pattern);
};

const formatWithTime = (date, pattern = "dd/MM/Y hh:mm") => {
  return format(date, pattern);
};

const formatFromString = (dateToFormat, pattern = "dd/MM/Y hh:mm") => {
  const dateTrim = dateToFormat.replace(",", "");
  const dateArray = dateTrim.split(" ");
  const day = dateArray[0];
  const date = dateArray[1];
  const month = dateArray[2];
  const year = dateArray[3];
  return `${DAYS[day]} ${date} ${MONTHS[month]} ${year} à ${dateArray[4]}`;
};

export { formatFromTimestamp, formatWithTime, formatFromString, format };
